<template>
  <div>
    <CButton
      class='ml-2'
      color='dark'
      variant='outline'
      @click='sshHandler'
      :disabled='!gitPath'
    >
      ssh
    </CButton>

    <CModal
      :title="getTitle()"
      :show.sync='isShowModal'
    >
      <div class='link' v-if='generatedKey'>{{ generatedKey }}</div>

      <div slot='footer' class='footer-buttons'>
        <CButton
          type='button'
          class='btn btn-secondary'
          @click='closeModal'
        > {{ $t('common.cancel') }}
        </CButton>
        <CButton
          type='button'
          class='btn btn-primary'
          @click='copyKey'
        > {{$t('smart.copyAndClose')}}
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>

import utilMessages from '../../../utils/messages';
import { mapGetters } from 'vuex';

export default {
  name: 'GitPathModal',
  components: {},

  props: {
    gitPath: String
  },

  data() {
    return {
      isShowModal: false,
      generatedKey: null
    };
  },

  computed: {
    ...mapGetters([
      'getGitRemotePath'
    ])
  },

  methods: {
    sshHandler() {
      this.isShowModal = true;
      this.getRemotePath();
    },

    async getRemotePath() {
      try {
        const response = await this.$store.dispatch('getRemotePath', {
          pathGit: this.gitPath,
          vm: this
        });
        if (response){
          this.generatedKey = response.public_ssh_key
        }
      } catch (err) {
        this.closeModal();
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    closeModal() {
      this.isShowModal = false;
    },

    async copyKey() {
      try {
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard.writeText(this.generatedKey);
          this.isShowModal = false;
          this.generatedKey = null;
        } else {
          let textArea = document.createElement("textarea");
          textArea.value = this.generatedKey;
          textArea.style.position = "fixed";
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
            this.isShowModal = false;
            this.generatedKey = null;
          });
        }
      } catch (e){
        console.log('error clipboard', e)
      }
    },
    getTitle(){
      return this.$t('smart.forConnect') + ' ' + this.gitPath + ' ' + this.$t('smart.copyAndAdd')
    }
  }
};
</script>

<style scoped>
.footer-buttons button {
  margin-left: 5px;
}

.tooltip-wrapper {
  width: fit-content;
}

.link {
  color: blue;
  text-decoration: underline;
}

</style>
