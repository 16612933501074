<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CRow>
    <CCol>
      <CCard class='object-table-wrapper'>
        <CCardHeader>
          <strong>{{ $t('smart.smartContractAdd') }}</strong>
        </CCardHeader>
        <CCardBody>
          <template>
            <CInput v-model='data.name' horizontal :label="$t('smart.nameLabel')" :placeholder="$t('smart.namePl')"
              addLabelClasses='font-weight-bold' :isValid='isValid.name' :tooltipFeedback=false
              :invalidFeedback='validationMessages.required' @change='$v.data.name.$touch()'>
            </CInput>

            <CSelect :label="$t('smart.version')" :options='chaincodeVersions' horizontal required
              :placeholder="$t('smart.chooseVersion')" addLabelClasses='font-weight-bold'
              :value.sync='data.blockchain_version' :isValid='isValid.blockchain_version' :tooltipFeedback=false
              :invalidFeedback='validationMessages.required' :disabled='true'
              @change='$v.data.blockchain_version.$touch()' />
            <CSelect :label="$t('smart.languageLabel')" :options='langs' horizontal required
              :placeholder="$t('smart.chooseLanguage')" addLabelClasses='font-weight-bold' :value.sync='data.lang'
              :isValid='isValid.lang' :tooltipFeedback=false :invalidFeedback='validationMessages.required'
              @change='$v.data.lang.$touch()' />
            <CInput v-model='data.git_remote_path' horizontal label='' :placeholder="$t('smart.remotePath')"
              addLabelClasses='font-weight-bold' :isValid='isValid.git_remote_path' :tooltipFeedback=false
              :invalidFeedback='validationMessages.required' @change='$v.data.git_remote_path.$touch()'>
              <div slot='label' class='col-sm-3 flexible font-weight-bold'>git-remote path*
                <div class='pathLink'>
                  <GitPathModal :gitPath='data.git_remote_path' />
                </div>
              </div>
            </CInput>
            <CInput v-model='data.git_branch' horizontal :label="$t('smart.branchLabel')"
              :placeholder="$t('smart.branch')" addLabelClasses='font-weight-bold' :isValid='isValid.git_branch'
              :tooltipFeedback=false :invalidFeedback='validationMessages.required'
              @change='$v.data.git_branch.$touch()'>
            </CInput>
            <CInput v-model='data.git_local_path' horizontal :label="$t('smart.localPathLabel')"
              :placeholder="$t('smart.localPath')" addLabelClasses='font-weight-bold' :isValid='isValid.git_local_path'
              :tooltipFeedback=false :invalidFeedback='validationMessages.required'
              @change='$v.data.git_local_path.$touch()'>
            </CInput>
          </template>
        </CCardBody>
        <CCardFooter>
          <CButton type='button' class='btn btn-primary' @click='createSmartContract' :disabled='!canCreate'> {{
              $t('common.create')
          }}
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions } from 'vuex';

import { required } from 'vuelidate/lib/validators';

import utilMessages from '../../../utils/messages';
import messages from '../messages';
import { ChainCodeVersion } from '../constants';
import { Languages } from './constants';
import GitPathModal from './GitPathModal';


export default {
  name: 'SmartContractCreate',
  components: { GitPathModal },
  props: {},

  data() {
    return {
      validationMessages: messages.validation,
      chaincodeVersion: ChainCodeVersion,
      languages: Languages,
      isFormShowed: false,
      data: {
        name: null,
        blockchain_version: ChainCodeVersion.HLF22.code,
        lang: null,
        git_remote_path: null,
        git_local_path: null,
        git_branch: null
      }
    };
  },

  validations: {
    data: {
      name: {
        required
      },
      blockchain_version: {
        required
      },
      lang: {
        required
      },
      git_remote_path: {
        required
      },
      git_local_path: {
        required
      },
      git_branch: {
        required
      }
    }
  },

  computed: {
    isValid() {
      let name, blockchain_version, lang,
        git_remote_path, git_local_path, git_branch;

      if (this.$v.data.name.$dirty)
        name = !this.$v.data.name.$invalid;

      if (this.$v.data.blockchain_version.$dirty)
        blockchain_version = !this.$v.data.blockchain_version.$invalid;

      if (this.$v.data.lang.$dirty)
        lang = !this.$v.data.lang.$invalid;

      if (this.$v.data.git_remote_path.$dirty)
        git_remote_path = !this.$v.data.git_remote_path.$invalid;

      if (this.$v.data.git_local_path.$dirty)
        git_local_path = !this.$v.data.git_local_path.$invalid;

      if (this.$v.data.git_branch.$dirty)
        git_branch = !this.$v.data.git_branch.$invalid;

      return {
        name,
        blockchain_version,
        lang,
        git_remote_path,
        git_local_path,
        git_branch
      };
    },

    canCreate() {
      return !this.$v.data.$invalid;
    },

    chaincodeVersions() {
      return Object.values(this.chaincodeVersion).map((item) => ({
        value: item.code,
        label: item.text
      }))
    },

    langs() {
      return Object.values(this.languages).map((item) => ({
        value: item.code,
        label: item.text
      }));
    }
  },

  methods: {
    ...mapActions(['setPendingTopic']),
    
    init() {
      this.data.name = null;
      this.data.blockchain_version = this.chaincodeVersion.HLF22.code;
      this.data.lang = null;
      this.data.git_remote_path = null;
      this.data.git_local_path = null;
      this.data.git_branch = null;

      this.$v.$reset();
    },

    async createSmartContract() {
      try {
        const response = await this.$store.dispatch('createSmartContract', {
          ...this.data
        });
        this.setPendingTopic(response.topic_id);
        this.$toast.success(this.$i18n.t('smart.smartContractCreated'));
        await this.$router.push({ path: `/smart-contracts/list` });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    }
  }

};
</script>

<style scoped>
button {
  margin-left: 5px;
}

.pathLink {
  width: 5%;
}

.flexible {
  display: flex;
  align-items: center;
}
</style>
